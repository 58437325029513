import axios from 'axios'
import Swal from 'sweetalert2'

let url = window.location.host
if (url == "localhost:8086") url = "hongwei-ch.test123.tw"
const base = 'https://' + url + '/api'


export const apiHelper = axios.create({
    baseURL: base
})
export const Toast = Swal.mixin({
    position: 'center',
    showConfirmButton: false,
    timer: 2500
})